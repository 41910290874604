<template>
	<div
		v-if="items.length > 0"
		class="section_wrap pt-50" style="padding-bottom: 70px"
	>
		<div class="notice_list_group">
			<ul>
				<!-- TODO : 신규글 NEW 배지는 <em> 태그에 .new 추가 -->
				<li
					v-for="(bbs, index) in items"
					:key="'bbs_' + index"
					style="overflow:hidden"

					@click="viewItem(bbs, index, bbs.notice_number)"
				>
					<!-- 제목 -->
					<div class="notice_list_subject list_flex">
						<div
							class="list_tit"
						>
							<span>{{ bbs.notice_title }}</span>
							<em
								:class="{new: bbs.new_reg_fg != 'N'}"
							>{{ bbs.registdate }}</em>
						</div>
						<div
							class="notice_list_subject_arrow"
						>
							<i
								:class="bbs.is_view ? 'icon-arrow-up' : 'icon-arrow-down'"
							></i>
						</div>
					</div>
					<!-- //제목 -->
					<!-- 내용 -->
					<transition name="slide-fade">
					<div
						class="notice_list_view"
						v-show="bbs.is_view"
					>
						<p :inner-html.prop="bbs.content | nl2br"></p>
						<div
							v-if="bbs.img_src"
							class="img_area"
						>
							<img
								:src="bbs.img_src" alt=""
							>
						</div>
					</div>
					</transition>
					<!-- //내용 -->
				</li>
			</ul>
		</div>
	</div>
	<div
		v-else
		class="flex_wrap pt-50"
	>
		<div class="intro_content">
			<div class="none_top character3_none">
				<div class="text_guide">
					<span class="cont_none">{{  $language.common.NotFoundNotice }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<style>

.slide-fade-enter-active {
	transition: all .3s ease;
}
.slide-fade-leave-active {
	transition: all .0s ease;
}
.slide-fade-enter{
	transform: translateY(-10%);

	overflow: hidden;
	height: 100%;
}
.slide-fade-leave, .slide-fade-leave-to {
	transform: translateY(-10%) ;
	opacity: 0;
	overflow: hidden;
}
</style>
<script>
	export default {
		name: 'bbs'
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: this.$language.common.community
					, title: this.$language.common.notice
					, b_code: this.$route.params.b_code
					, type: 'mypage'
				}
				,items : []
			}
		}
		, methods: {
			getData: async function(){
				try {
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_notice_list
						, data: {
							member_number: this.user.member_number
							, page_number: this.$language.base.page_number
							, pagerecnum: this.$language.base.pagerecnum
						}
						, type: true
					})
					if (result.success) {
						this.items = result.data.notice_mttrs_list
					} else {
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify',  { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,viewItem : async function(item, index, notice_number){

				try {
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_notice_info
						, data: {
							member_number: this.user.member_number
							, notice_number: notice_number
						}
						, type: true
					})
					if (result.success) {
						item.content = result.data.notice_contents
					} else {
						throw result.message
					}

					if(!item.is_view){
						for(let i = 0; i < this.items.length; i++){
							this.items[i].is_view = false
						}
						item.is_view = true
						this.$set(this.items, index, item)
					}else{
						item.is_view = false
						this.$set(this.items, index, item)
					}

				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify',  { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		,created() {
			this.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>